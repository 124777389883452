<template>
  <div  class="manager-container">
    <div class="query-item button-bar">
      <el-button type="danger" round @click="handleClearCacheClick()">
        Clear MPA Product Cache
      </el-button>
      <el-button
        v-if="hasPermission($permissionFunctionCode.ADD)"
        round
        type="primary"
        @click="handleCreateProductClick()"
      >
        Create Product
      </el-button>
    </div>
    <div class="table-block">
      <el-table :data="productList"
      :cell-style="CELL_STYLE"
      :header-cell-style="HEADER_CELL_STYLE"
      height="100%"
      >
        <el-table-column label="Name">
          <template v-slot:header><span style="color: red;">* </span>Name</template>
          <template v-slot="scope">
            <el-input v-model="products[scope.$index].name" @blur="handleEditProduct(scope.$index, field='name')"/>
          </template>
        </el-table-column>
        <el-table-column label="Oil Type">
          <template v-slot="scope">
              <el-select
                filterable
                style="width: 100%"
                v-model="products[scope.$index].oilType"
                @change="handleEditProduct(scope.$index)"
              >
                <el-option
                  v-for="item in OIL_TYPE_OPTIONS"
                  :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
          </template>
        </el-table-column>
        <el-table-column label="MPA Code">
          <template v-slot="scope">
              <el-select
                filterable
                clearable
                style="width: 100%"
                v-model="products[scope.$index].mpaCode"
                @change="handleEditProduct(scope.$index)"
              >
                <el-option
                  v-for="code in mpaCodeList"
                  :key="code.fuelTypeCode"
                  :label="code.fuelTypeCode ? `${code.fuelTypeCode} (${code.fuelTypeDescription})` : 'Select'"
                  :value="code.fuelTypeCode">
                </el-option>
              </el-select>
          </template>
        </el-table-column>
        <el-table-column label="Updated At" width="200px">
          <template v-slot="scope">
            <p>{{scope.row.updatedAt ? $moment(scope.row.updatedAt).format('YYYY-MM-DD HH:mm:ss') : "-"}}</p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <dialog-add-product
      :dialogAddProduct="dialogAddProduct"
      @handleAddProductDialogClose="dialogAddProduct = false"
      @handleAddEditProductSuccess="handleAddEditProductSuccess"
      :mpaCodeList="mpaCodeList"
      :productSelected="productSelected"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { CELL_STYLE, HEADER_CELL_STYLE, OIL_TYPE_OPTIONS } from '@/constants.js'
import DialogAddProduct from './DialogAddProduct.vue'
export default {
  name: 'ProductList',
  components: { DialogAddProduct },
  data () {
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE,
      OIL_TYPE_OPTIONS,
      dialogAddProduct: false,
      productSelected: null,
      mpaCodeList: [],
      tempProducts: []
    }
  },
  computed: {
    ...mapState(['productList']),
    products: function () {
      return this.productList
    }
  },
  created () {
    this.tempProducts = this.$_.cloneDeep(this.productList)
    this.$request.get({
      url: this.$apis.mpaBunkerFuelTypes
    }).then(res => {
      if (res?.code === 1000) {
        this.mpaCodeList = res.data || []
        this.mpaCodeList.unshift({
          fuelTypeCode: null,
          fuelTypeDescription: null
        })
      }
    })
  },
  methods: {
    handleCreateProductClick () {
      this.productSelected = {
        id: null,
        name: null,
        oilType: null,
        mpaCode: null
      }
      this.dialogAddProduct = true
    },
    handleEditProduct (index, field) {
      if (field === 'name') {
        if (!this.products[index].name) {
          this.products[index].name = this.tempProducts[index].name
          this.$message.error('Product name cannot be empty')
          return
        }
        if (this.products[index].name === this.tempProducts[index].name) {
          return
        }
      }
      this.$request.put({
        url: this.$apis.products,
        data: this.products[index]
      }).then(res => {
        if (res?.code === 1000) {
          const successMsg = 'Product updated successfully'
          this.handleAddEditProductSuccess(successMsg)
        }
      })
    },
    handleAddEditProductSuccess (successMsg) {
      this.$store.dispatch('getProductList').then(() => {
        this.tempProducts = this.$_.cloneDeep(this.productList)
        this.$message.success(successMsg)
      })
      this.dialogAddProduct = false
    },
    handleClearCacheClick () {
      this.$request.delete(this.$apis.mpaBunkerFuelTypes).then(res => {
        if (res?.code === 1000) {
          this.$message.success('MPA product cache cleared')
        }
      })
      this.$store.dispatch('getProductList')
      this.$store.dispatch("getMpaFuelTypeList");
    }
  }
}
</script>
<style lang="scss" scoped>
.manager-container {
  height: 100vh;
  width: 100%;
  .table-block {
    height: calc(100% - 200px);
  }
  .button-bar {
    padding: 8px 0;
    float: right;
  }
}
</style>
